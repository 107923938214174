/* eslint-disable quotes */
// 最大14位正数且 保留2位小数
export const positiveThirteenAndSix = /^-?(\d{1,13})(\.\d{1,2})?$/;
// 负数、整数、小数
export const positiveNumber = /^-?\d+(\.\d+)?$/;
// 负数、0、正数
export const isNumber = /^-?(0|[1-9]\d*)$/;
// 大于0，不包含0
export const greaterThanZore = /^(?!0*(\.0+)?$)\d+(\.\d+)?$/;
// 小于0，不包含0
export const lessThanZore = /^-[1-9]\d*(\.\d+)?$|^-0\.\d*[1-9]\d*$/;
/* eslint-disable quotes */
