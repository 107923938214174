<template>
    <div class="collapse-infos">
        <el-collapse
            class="contract-collapse detail-form-collapse"
            v-model="collapseValue">
        <!-- 表单信息区域 -->
            <el-collapse-item
                :name="collapseItem.formName"
                v-for="(collapseItem, index) in newCollapse"
                :key="index">
                <template slot="title">
                    <slot
                        v-if="!!$scopedSlots['collapse-title-append']"
                        name="collapse-title-append"
                        v-bind="{ collapseItem }">
                    </slot>
                    <span v-else>{{ collapseItem.title }}</span>
                </template>
                <template>
                    <dc-form
                        :loading="addNewMainLoading"
                        ref='dcForm'
                        :refName="collapseItem.ref"
                        :row="formData[collapseItem.formName]"
                        :config="collapseItem.formList"
                        :col="collapseItem.col"
                        @data-changed="handleFormDataChange"
                        :labelWidth="collapseItem.labelWidth">
                        <template v-slot:form-append="{form, itemSetting, rIndex}">
                        <el-form-item
                                :prop="itemSetting.prop"
                                :label-width="collapseItem.labelWidth">
                                <template #label>
                                    <span>{{itemSetting.label}}</span>
                                    <el-tooltip >
                                        <template #content>
                                            {{itemSetting.content}}
                                        </template>
                                        <i class="el-icon-info tips-color" v-if="itemSetting.content" />
                                    </el-tooltip>
                                </template>
                                <span v-if="itemSetting.optionsKey"
                                    class="custom-span">
                                    <el-tooltip
                                        v-if="itemSetting.prop === 'businessType'"
                                        effect="dark"
                                        :content="businessTypesShow || ''"
                                        placement="top-start">
                                        <span class="custom-span">{{businessTypesShow || '--'}}</span>
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else-if="itemSetting.multiple"
                                        effect="dark"
                                        :content="multiDictPropShow(form[itemSetting.prop], itemSetting.optionsKey) || ''"
                                        placement="top-start">
                                        <span class="custom-span">{{multiDictPropShow(form[itemSetting.prop], itemSetting.optionsKey) || '--'}}</span>
                                    </el-tooltip>
                                    <span
                                        v-else
                                        :title="dictDataPropShow(form[itemSetting.prop], itemSetting.optionsKey) || ''">
                                        {{dictDataPropShow(form[itemSetting.prop], itemSetting.optionsKey) || '--'}}
                                    </span>
                                </span>
                                <el-input
                                    v-else-if="itemSetting.prop === 'changeDetail'"
                                    type="textarea"
                                    :rows="5"
                                    :readonly="true"
                                    placeholder="--"
                                    v-model="form[itemSetting.prop]">
                                </el-input>
                                 <slot
                                    v-else-if="itemSetting.customSlot"
                                    :name="itemSetting.customSlot|| 'other-append'"
                                    v-bind="{form, itemSetting, rIndex, collapseItem, dictData}">
                                </slot>
                                <slot
                                    v-else-if="!!$scopedSlots['collapse-append']"
                                    name="collapse-append"
                                    v-bind="{form, itemSetting, rIndex, collapseItem, dictData}">
                                </slot>
                                <span v-else class="custom-span"
                                    :title="form[itemSetting.prop]">{{(form[itemSetting.prop] || form[itemSetting.prop] === 0)
                                    ? form[itemSetting.prop]
                                    : (itemSetting.prop === 'receiptRecoveryDays' ? '(=合同全账期-票后回款-对账开票)' : '--')}}
                                </span>
                        </el-form-item>
                    </template>
                    </dc-form>
                </template>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
import { getSealAdminList, queryEfOrganRelationsApi, getSrmCompanyInfosApi } from '@mdm/api/contractManage/detailMainApi.js';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import _ from 'lodash';
import { MessageBox } from 'element-ui';
import {
    STATE_NEW,
    STATE_OTHER_CHANGE
} from '@/modules/mdm/constant/contractManage.js';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import utils from '@/components/utils/common.js';
import { positiveNumber } from '@/modules/mdm/utils/regList.js';
const { MAGIC_NUMBER } = utils;
export default {
    components: { dcForm },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        collapse: {
            type: Array,
            default() {
                return [];
            }
        },
        activeCollapses: {
            type: Array,
            default() {
                return ['basicInfo', 'financeInfo', 'oilPriceInfo'];
            }
        },
        showTips: {
            type: Boolean,
            default: true
        },
        addedDictKeys: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            newConfig: [],
            collapseValue: [...this.activeCollapses],
            localizeData: _.cloneDeep(this.detailData), // 详情原始数
            formData: {
                // 表单存储数据的对象
                basicInfo: {
                    businessType: []
                }, // 基础信息表单数据
                financeInfo: {}, // 财务信息表单数据
                oilPriceInfo: {}, // 油价信息表单数据
                capabilityInfo: {}, // 运营能力
                importantInfo: {}
            },
            showForm: false,
            sealAdminList: [], // 印章管理员options选项列表
            sealDepartmentNumber: '', // 定义一个印章部门编码
            addNewMainLoading: false,
            dictData: {}
        };
    },
    computed: {
        businessTypesShow() {
            const showArr = [];
            if (this.detailData.businessType && this.detailData.businessType.length && Array.isArray(this.detailData.businessType)) {
                this.detailData.businessType.forEach(item => {
                    if (this.dictData['SYS_BMS_BUSI_TYPE']) {
                        showArr.push(this.dictData['SYS_BMS_BUSI_TYPE'][item]);
                    }
                });
            }
            return String(showArr);
        },
        multiDictPropShow() {
            function dictPropShowFn(data, optionsKey) {
                let newData = _.cloneDeep(data);
                if (newData.length && !Array.isArray(newData)) {
                    newData = newData.split(',');
                }
                const showArr = [];
                if (newData && newData.length) {
                    newData.forEach(item => {
                        if (this.dictData[optionsKey]) {
                            showArr.push(this.dictData[optionsKey][item]);
                        }
                    });
                }
                return String(showArr);
            }
            return dictPropShowFn;
        },
        dictDataPropShow() {
            function dictPropShowFn (data, optionsKey) {
                return (data || data === 0) && this.dictData[optionsKey] ? this.dictData[optionsKey][String(data)] : '';
            };
            return dictPropShowFn;
        },
        newCollapse() {
            const arr = this.collapse.filter(item => item.visible !== false);
            return arr;
        }
    },
    watch: {
        activeCollapses(newVal, oldVal) {
            this.collapseValue = [...newVal];
        }
    },
    async created() {
        // this.refleshPage(); // 父组件挂载后已经手动触发this.refleshPage()
        await this.getDictData();
    },
    methods: {
        // 更新最新初始数据
        updataInitData (val) {
            this.localizeData = this.updateWithChanges(this.localizeData, val);
            this.dataInit();
        },
        // 合并字段，没有改动的字段不变，可新增，可修改字段
        updateWithChanges(target, source) {
            return _.mergeWith({}, target, source, (targetValue, sourceValue, key) => {
                return _.isEqual(targetValue, sourceValue) ? targetValue : sourceValue;
            });
        },
        async getDictData() {
            const dictCodes = ['NEOCRM_BUSINESS_TYPE', 'NEOCRM_WH_TYPE', 'PMS_CUSTOMER_CLASSIFY', 'CRM_REFERENCE_ATTACHMENT']; // 专用字典：商机大类、仓库类型、客户分类; 参考附件
            if (this.addedDictKeys) {
                dictCodes.push([...this.addedDictKeys.split(',')]);
            }
            this.collapse.forEach(item => {
                item.formList.forEach(sub => {
                    if (sub.optionsKey) {
                        dictCodes.push(sub.optionsKey);
                    }
                });
            });
            const res = await getDictDataApi({ dictCodes: String(dictCodes) });
            this.dictData = res.data;
        },
        // 刷新页面
        async refleshPage(data) {
            this.localizeData = data ? _.cloneDeep(data) : _.cloneDeep(this.detailData);
            if (this.localizeData.accountEntityId) {
                await this.queryEfOrganRelations(this.localizeData.accountEntityId);
            }
            this.dataInit();
        },
        // 获取会计主体id: legalSysId，用于后续查询印章管理员
        async queryEfOrganRelations(accountEntityId) {
            await queryEfOrganRelationsApi({ operatingUnitAccurateId: accountEntityId || this.localizeData.accountEntityId }).then(res => {
                if (+res.code === 0) {
                    const arr = this.localizeData.accountEntityName.split('_');
                    const theLegalEntityName = arr[arr.length - 1];
                    const _data = res.data.list.filter(it => +it.operatingUnitId === +accountEntityId) || [];
                    if (_data.length) {
                        this.localizeData.legalEntityName = _data[0].legalEntityName || theLegalEntityName;
                    } else {
                        this.localizeData.legalEntityName = theLegalEntityName;
                    }
                }
            });
            await this.getLegalSysId();
            if (+this.localizeData.stampMode === 2) {
                this.getSealAdmin(this.localizeData);
            }
        },
        // 根据legalEntityName 获取会计主体实体Id legalSysId
        async getLegalSysId(legalEntityName) {
            await getSrmCompanyInfosApi({ legalEntityName: legalEntityName || this.localizeData.legalEntityName }).then(res2 => {
                if (+res2.code === 0 && res2.data.list && res2.data.list.length) {
                    this.localizeData.legalSysId = res2.data.list[0].legalSysId;
                }
            });
        },
        // 获取印章管理员列表
        getSealAdmin(form) {
            // 重置管理员选项
            this.collapse[0].formList.forEach((v, i) => {
                if (v.prop === 'sealAdminAccount') {
                    v.options = [];
                }
            });
            const { legalSysId, sealCategory } = form;
            const inputObj = {
                type: sealCategory,
                belongs: legalSysId ? [String(legalSysId)] : [], // ['1722294'] 有数据
                // belongs: ['1722294'],
                sealDepartmentNumber: '30003357' // 固定安得部门
            };
            getSealAdminList(inputObj).then((res) => {
                if (res && res.code === '0') {
                    const { data } = res;
                    if (data && data.length) {
                        this.sealDepartmentNumber = data[0].sealList[0].sealDepartmentNumber;
                        this.sealAdminList = data[0].sealList.map((v, i) => {
                            return {
                                value: v.sealAdminAccount,
                                label: v.sealAdminName
                            };
                        });
                        this.collapse[0].formList.forEach((v, i) => {
                            if (v.prop === 'sealAdminAccount') {
                                v.options = this.sealAdminList;
                            }
                        });
                    }
                }
            });
        },
        // 数据初始化
        dataInit() {
            this._initForm();
        },
        _initForm() { // 表单数据填充：
            // this.newCollapse.forEach((item) => { // 不能用this.newCollapse，因为初始化时，动态加载的项目（如：中风险引入承诺、CDC仓准入）两项并不存在
            // 使用this.newCollapse回显数据的话，因此会导致数据初始化填充遗漏这些项目，从而导致数据丢失
            this.collapse.forEach((item) => {
                this.formData[item.formName] = {}; // 初始化置空数据
                const formItemData = {};
                const { contractAccountOf, collectionDays, reconciliationInvoicingDays, receiptRecoveryDays } = this.localizeData;
                // eslint-disable-next-line complexity
                item.formList.forEach((formItem) => {
                    if (formItem.prop === 'receiptRecoveryDays') {
                        // 初始化自动计回单收回天数
                        const newReceiptRecoveryDays = +(contractAccountOf || 0) - +(collectionDays || 0) - +(reconciliationInvoicingDays || 0);
                        formItemData['receiptRecoveryDays'] = receiptRecoveryDays || newReceiptRecoveryDays;
                    } else if (formItem.prop === 'businessType' && formItem.multiple) {
                        formItemData[formItem.prop] = this.localizeData[formItem.prop] || [];
                        if (formItem.rule) {
                            const timer = setTimeout(() => {
                                formItem.rule[0].required = !(this.detailPageState === STATE_OTHER_CHANGE && +this.localizeData.changeType !== 0); // 解决初始化会触发一次 业务类别必填警告的bug
                                clearTimeout(timer);
                            });
                        }
                    } else if (formItem.prop === 'capacityBuildProject' && formItem.multiple) {
                        formItemData[formItem.prop] = this.localizeData[formItem.prop] || [];
                        formItem.rule[0].required = true; // 解决初始化会触发一次 多选必填警告的bug
                    } else {
                        formItemData[formItem.prop] = this.localizeData[formItem.prop];
                    }
                });
                this.formData[item.formName] = Object.assign( // 把源数据分别组装到对应子表单
                    {},
                    this.formData[item.formName],
                    formItemData
                );
            });
            this.$forceUpdate();
        },
        formatData(data) { // 服务类型字符串数组转换函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            return data;
        },

        // eslint-disable-next-line max-lines-per-function, complexity
        async handleFormDataChange({ data, form, itemSetting, refName }) { // 修改表单数据获取
            form['sealDepartmentNumber'] = this.sealDepartmentNumber;
            if (['validTime', 'failTime', 'oilIsGroupCustomer'].includes(itemSetting.prop)) {
                if (form['validTime'] && form['failTime']) {
                    if (new Date(form['failTime']).getTime() < new Date(form['validTime']).getTime()) {
                        form[itemSetting.prop] = '';
                        return this.$message.warning('失效时间不能早于生效时间');
                    }
                    // 其他变更，合同性质为预签，内外部业务为外部1时，需校验生效、失效日期时间差不能超过30天
                    if (this.detailPageState === STATE_OTHER_CHANGE && +form.contractCharacter === MAGIC_NUMBER.FOUR && +form.oilIsGroupCustomer === 1) {
                        const monthTamp = 2592000000;
                        // eslint-disable-next-line max-depth
                        if ((new Date(form['failTime']).getTime() - new Date(form['validTime']).getTime()) > monthTamp) {
                            form[itemSetting.prop] = '';
                            return this.$message.warning('预签合同变更，外部业务时，失效时间与生效时间间隔不能超过30天');
                        }
                    }
                }
            }
            // let continueFlag = true;
            if (itemSetting.prop === 'projectCode') { // 操作项目编码
                const projectKeys = ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo'];
                if (!data.projectCode) {
                    projectKeys.forEach(item => {
                    // Reflect.deleteProperty(form, item); // 删除属性 // 方法无效，需采用$set
                        this.$set(form, item, '');
                        this.$set(this.localizeData, item, '');
                        form['contractSource'] = '1'; // 合同来源 CRM
                    });
                    this.$emit('change', { data, form: this.localizeData, itemSetting });
                    return false;
                };
                if (!['6', '8'].includes(form['projectStatus'])) {
                    setTimeout(() => {
                        projectKeys.forEach(item => {
                            // Reflect.deleteProperty(form, item); // 删除属性 // 方法无效，需采用$set
                            this.$set(form, item, '');
                        });
                    });
                    return this.$message.warning('请关联中标项目');
                }
            }
            const NumberFields = ['contractCount', 'contractAccountOf', 'receiptRecoveryDays', 'reconciliationInvoicingDays', 'collectionDays',
                'expectedTrafficPlan', 'operationScale', 'grossMarginPlan', 'operatGrossMarginPlan', 'deposit',
                'acceptanceRate', 'feeRate', 'triggerOilPriceFloat', 'freightLinkedScale', 'freightFloatCoefficient', 'profitMargin'
            ];
            if (NumberFields.includes(itemSetting.prop)) {
                form[itemSetting.prop] = data.replace(/\s*/g, '');
                const contractCountReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/; // 非负数
                const strData = data.replace(/\s*/g, '') || 0;
                if (!(contractCountReg.test(Number(strData)))) {
                    form[itemSetting.prop] = '';
                    return this.$message.warning('请输入正确的数字值');
                }
                if (['contractAccountOf', 'collectionDays', 'reconciliationInvoicingDays', 'receiptRecoveryDays'].includes(itemSetting.prop)) {
                    const smallintReg = /^[+]{0,1}(\d+)$/;
                    if (!(smallintReg.test(Number(strData)))) {
                        form[itemSetting.prop] = '';
                        return this.$message.warning('请输入有效整数');
                    }
                    const { contractAccountOf, collectionDays, reconciliationInvoicingDays } = form;
                    form.receiptRecoveryDays = (+(contractAccountOf || 0) - +(collectionDays || 0) - +(reconciliationInvoicingDays || 0));
                    if (form.receiptRecoveryDays < 0) {
                        form[itemSetting.prop] = '';
                        form['receiptRecoveryDays'] = '';
                        return this.$message.warning('回单收回天数不能为负数');
                    }
                }
            }
            if (itemSetting.prop === 'stampMode' && data === '2') {
                form['sealAdminAccount'] = '';// 重新获取印章管理员前置空原数据
                this.getSealAdmin({ ...this.localizeData, ...form });
            }
            if (itemSetting.prop === 'sealCategory' || itemSetting.prop === 'accountEntityName') { // 更改印章类型 或 会计主体
                form['sealAdminAccount'] = ''; // 重新获取印章管理员前置空原数据
                if (itemSetting.prop === 'accountEntityName' && form.legalEntityName) {
                    await this.getLegalSysId(form.legalEntityName);
                }
                if (+form['stampMode'] === MAGIC_NUMBER.TWO) { // 盖章方式为纸质
                    this.getSealAdmin({ ...this.localizeData, ...form });
                }
            }
            if (itemSetting.prop === 'sealAdminAccount') {
                this.sealAdminList.forEach((item) => {
                    if (item.value === data) {
                        form['sealAdminName'] = item.label;
                    }
                });
            }
            if (itemSetting.prop === 'cooperationQuota' && positiveNumber.test(form['cooperationQuota'])) {
                // 超出2位小数，截取多余小数
                form['cooperationQuota'] = form['cooperationQuota'].replace(/(\.\d{2})\d*/, '$1');
            }
            // if (!continueFlag) { return false; }
            if (itemSetting.prop === 'oilLinkedProportion' && data === '0' && form.linkageType && this.detailPageState !== STATE_NEW) { // 油价联动为否，且有联动类型
                MessageBox.confirm('请确认是否清空油价联动信息以及油价子页签信息？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$set(form, 'oilLinkedProportion', '0');
                    this.localizeData = Object.assign({}, this.localizeData, form); // 编辑时同步更新当前页详情数据
                    this.$emit('change', { data, form: this.localizeData, itemSetting });
                }).catch(res => {
                    form.oilLinkedProportion = '1';
                });
            } else {
                this.localizeData = Object.assign({}, this.localizeData, form); // 编辑时同步更新当前页详情数据
                this.$emit('change', { data, form: this.localizeData, itemSetting, targetForm: form, refName });
            }
        },
        // 表单保存
        onsave() {
            let rulesConfirm = true;
            let validObj = {};
            if (this.$refs.dcForm && this.$refs.dcForm.length) {
                this.$refs.dcForm.forEach((item, index) => {
                    item.$refs[this.newCollapse[index].ref] && item.$refs[this.newCollapse[index].ref].validate((valid, object) => {
                        validObj = Object.assign({}, validObj, object);
                        if (!valid) {
                            rulesConfirm = false;
                        }
                    });
                });
            }
            if (rulesConfirm) {
                return this.localizeData;
            } else {
                if (this.showTips) {
                    const labels = this.getRequiredLabels(validObj);
                    this.$message({ type: 'warning', message: `${labels} 为必填项！` });
                }
                return false;
            }
        },
        clearValidate() {
            if (this.$refs.dcForm && this.$refs.dcForm.length) {
                this.$refs.dcForm.forEach((item, index) => {
                    item.$refs[this.newCollapse[index].ref] && item.$refs[this.newCollapse[index].ref].clearValidate();
                });
            }
        },
        // 获取所有form的校验项名称集合
        getRequiredLabels(data) {
            const labels = [];
            this.newCollapse.forEach((col) => {
                col.formList.forEach((fLi) => {
                    if (data[fLi.prop]) {
                        if (fLi.prop === 'requestContent') {
                            labels.push('请示内容');
                        } else {
                            labels.push(fLi.label);
                        }
                    }
                });
            });
            return labels.join(' | ');
        }
    }
};
</script>
<style lang="less">
// @import './collapseInfos.less';
.collapse-infos {
    .contract-collapse {
        .el-select,
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
        width: 100%;
        }
    }
    .detail-form-collapse.el-collapse {
        border-top: none;
        .el-form-item__content {
            .custom-span {
                color: #606266;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .el-input.is-disabled .el-input__inner {
        color: #606266;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        color: #606266;
    }
    .tips-color{
        color: #4285f5;
        margin-left: 3px;
    }
}
</style>
