
/* eslint-disable no-magic-numbers */
// 合同常量

// SYS_CR_CONTRACT_STATUS 合同状态
export const DRAFT = '0'; // 草稿
export const APPEND = '1'; // 新增
export const EFFECT = '2'; // 有效
export const SUSPEND = '3'; // 中止
export const ABORT = '4'; // 终止
export const FAILURE = '5'; // 失效
export const PASS = '6'; // 审批通过
export const ABANDON = '7'; // 合同废弃
export const DISCARD = '8'; // 作废
export const APPROVING = '9'; // 审批中

// SYS_CR_CONTRACT_STATUS 合同状态
export const CONTRACT_STATUS = {
    DRAFT: '0', // 草稿
    APPEND: '1', // 新增
    EFFECT: '2', // 有效
    SUSPEND: '3', // 中止
    ABORT: '4', // 终止
    FAILURE: '5', // 失效
    PASS: '6', // 审批通过
    ABANDON: '7', // 合同废弃
    DISCARD: '8', // 作废
    APPROVING: '9' // 审批中
};

// SYS_WM_CONTRACT_CHARACTER 合同性质
export const CONTRACT_CHARACTER = {
    NEW: '1', // 新签
    RENEW: '2', // 续签
    REPLENISH: '3', // 补充协议
    ADVANCE: '4', // 预签
    TERMINATION: '5' // 终止协议
};

// SYS_BMS_NEW_MIP_STATUS mip流程状态
export const MIP_STATUS = {
    DRAFT: '00', // 草稿
    SUBMIT: '10', // 提交
    ADOPT: '20', // 通过
    REJECT: '30', // 驳回
    ABANDONMENT: '40', // 废弃
    WITHDRAW: '80', // 撤回
    PROCESSING: '90', // 处理中
    OTHER: '100' // 其他
};

// SRM_CONTRACT_SOURCE 合同来源
export const BIDING = '2'; // 招标

// 合同tab页状态
export const STATE_VIEW = 'view'; // 查看
export const STATE_MAIN = 'main'; // 主合同
export const STATE_STOP = 'stop'; // 终止协议
export const STATE_CHANGE = 'change'; // 变更
export const STATE_OTHER_CHANGE = 'otherChange'; // 其他变更
export const STATE_NEW = 'new'; // 新增
export const STATE_REPLENISH = 'replenish'; // 补充协议
export const STATE_PRICE = 'price'; // 价目表（主合同价格维护页）

// 合同申请状态
export const APPLY_TYPES_MAIN = '1'; // 主合同
export const APPLY_TYPES_CHANGE = '2'; // 合同变更
export const APPLY_TYPES_STOP = '4'; // 合同终止
export const APPLY_TYPES_REPLENISH = '5'; // 补充协议
export const APPLY_TYPES_PRICE = '6'; // 价格维护
export const APPLY_TYPES_OTHER_CHANGE = '7'; // 合同其他变更

// SRM_CONTRACT_TYPE_ALL 合同类型
export const PRESIGN = '3'; // 预签合同

// SRM_CONTRACT_WH_NEW_RENEWAL 新签/续签-仓储
export const NEW_BUSINESS = '1'; // 新业务/新仓

// SRM_CONTRACT_OTHER_NEW_RENEWAL 新签\续签-非仓储
export const NEW_SIGN = '10'; // 新签或新业务
export const OLD_BUSINESS_ORIGIN_SUPPLIER = '20'; // 新/续签--老业务原供方
export const OLD_BUSINESS_NEW_SUPPLIER = '30'; // 新/续签--老业务新供方

// SRM_NO_LOGISTICS_CONTRACT_TYPE 合同类型（非物流）
export const CONTRACT_DEVICE_TYPE = '2'; // 设备类

// SRM_BUSYNESS_TYPE_NO_LOGISTICS 业务类型（非物流）
export const DEVICE_TYPE = '11'; // 设备类

// SRM_CONTRACT_BUSINESS_CATEGORY 业务分类
export const DEVICE_LEASE = '8'; // 设备租赁
export const DEVICE_PURCHASE = '9'; // 设备采购

// SRM_LEASED_ASSETS_TYPE 租赁资产类型
export const HOUSE_BUILDING = '1'; // 房屋建筑物
export const ELECTRONIC_DEVICE = '5'; // 电子设备

// SRM_DEVICE_TYPE 设备类型
export const FORK_LIFT = '1'; // 叉车
export const TRAY = '2'; // 托盘
